/* istanbul ignore file: todo tests @titk */

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/unbound-method */
import React, {CSSProperties, RefObject} from 'react';
import _ from 'lodash';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import {classes as quantityStyle, style as styleQuantity} from './QuantityCounter.st.css';
import autobind from 'auto-bind-es5';
import classNames from 'classnames';
import s from './ProductItem.scss';
import {classes as productItemStylable, st as productItemStyles} from './ProductItem.st.css';
import {Counter, Text} from 'wix-ui-tpa/cssVars';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {HoverType, IProduct, RibbonPlacementId} from '../../../types/galleryTypes';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {withGlobals} from '../../../globalPropsContext';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {ProductOptionsChangeData, ProductOptionsWithGlobals} from './ProductOptions/ProductOptions';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {ProductOptionType} from '@wix/wixstores-graphql-schema';
import {ProductMedia} from './ProductMedia/ProductMedia';
import {ProductRibbon} from './Ribbon/Ribbon';
import {AddToCartButton as AddToCart} from './AddToCartButton/AddToCartButton';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotContextProvider} from '@wix/widget-plugins-ooi-context';
import {AddToCartActionStatus, SlotIds} from '../../../constants';
import {StatesButton} from 'wix-ui-tpa';
import {ConditionalRender} from '../../../category/components/ConditionalRender/ConditionalRender';

export enum DataHook {
  AddToCartButton = 'product-item-add-to-cart-button',
  AddToCartLoadingIndicator = 'product-item-add-to-cart-loading-indicator',
  LineBetweenNameAndPrice = 'product-item-line-between-name-and-price',
  LinkContainer = 'product-item-container',
  Name = 'product-item-name',
  Price = 'product-item-price',
  ProductDetails = 'product-item-product-details',
  ProductDetailsLink = 'product-item-product-details-link',
  QuantityCounter = 'product-item-quantity-counter',
  QuantityCounterWrapper = 'product-item-quantity-counter-wrapper',
  QuickViewButton = 'product-item-quick-view-button',
  Ribbon = 'product-item-ribbon',
  Root = 'product-item-root',
  SrOnlyName = 'product-item-sr-only-name',
  Options = 'product-item-options',
  NotImageContainer = 'not-image-container',
  DiscountRuleName = 'product-discount-rule-name',
  SlotsPlaceholderContainer = 'slots-placeholder',
}

export interface IProductItemProps extends IGalleryGlobalProps {
  a11yAnnouncer: Announcer;
  index: number;
  product: IProduct;
  innerRef?: Function;
  disabled?: boolean;
  style?: CSSProperties;
}

interface IProductItemState {
  errors: {quantity?: string};
  limits: {quantity: {min: number; max: number}};
  selections: number[];
  quantity: number;
  showHoverPreview: boolean;
  isHovered: boolean;
}

const PREVIEW_DURATION = 1000;

export class ProductItem extends React.Component<IProductItemProps & IProvidedTranslationProps, IProductItemState> {
  private readonly debouncedStopHoverPreview = _.debounce(
    () => this.setState({showHoverPreview: false}),
    PREVIEW_DURATION
  );
  private readonly productLink = React.createRef<HTMLAnchorElement>();
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();
  public state: IProductItemState = {
    isHovered: false,
    showHoverPreview: false,
    quantity: 1,
    selections: [],
    errors: {quantity: undefined},
    limits: {
      quantity: {
        min: 1,
        max: 1,
      },
    },
  };

  private hoverType: string;

  constructor(props) {
    super(props);
    autobind(this);

    const {styles, stylesParams} = this.props.globals;
    this.hoverType = styles.get(stylesParams.gallery_hoverType).value;
  }

  public static getDerivedStateFromProps(props: IProductItemProps): Partial<IProductItemState> {
    const inventoryRange = QuantityCalculator.getQuantitiesRange(props.product);
    const inventoryCount = inventoryRange[inventoryRange.length - 1];

    return {
      limits: {
        quantity: {
          min: 1,
          max: inventoryCount,
        },
      },
    };
  }

  public focus(): void {
    this.productLink.current.focus();
  }

  private renderSrOnlyName() {
    return (
      <span data-hook={DataHook.SrOnlyName} className={a11y.srOnly}>
        {this.props.product.name}
      </span>
    );
  }

  private renderName() {
    const {
      product,
      globals: {shouldShowMobile},
    } = this.props;

    const htmlTag = this.props.globals.htmlTags.productNameHtmlTag;
    const classes = classNames(
      productItemStyles(productItemStylable.root, {
        useMobileFont: shouldShowMobile,
      }),
      s.productName
    );

    return (
      <Text tagName={htmlTag} className={classes} data-hook={DataHook.Name}>
        {product.name}
      </Text>
    );
  }

  private renderDiscountRuleName() {
    const {product} = this.props;
    const classes = classNames(s.productDiscountRuleName);
    const discountRuleName = product.itemDiscount?.discountRuleName;

    return (
      discountRuleName && (
        <Text className={classes} data-hook={DataHook.DiscountRuleName}>
          {discountRuleName}
        </Text>
      )
    );
  }

  private renderImage() {
    const {
      globals: {
        shouldShowMobile,
        imageMode,
        imageRatio,
        textsMap: {digitalProductBadgeAriaLabelText},
        styles,
        stylesParams,
      },
      product,
    } = this.props;

    const hoverType = styles.get(stylesParams.gallery_hoverType).value;

    const productWithCurrentMedia = this.canAddProductToCart
      ? {
          ...product,
          media: this.currentMedia,
        }
      : product;

    return (
      <ProductMedia
        classNames={{thumbnail: s.productThumbnail, image: s.productImage}}
        product={productWithCurrentMedia}
        isMobile={shouldShowMobile}
        hoverType={hoverType}
        imageRatioId={imageRatio}
        imageModeId={imageMode}
        globals={this.props.globals}
        textsMap={{digitalProductBadgeAriaLabel: digitalProductBadgeAriaLabelText}}>
        {product.ribbon && (
          <ConditionalRender by={'shouldShowRibbonOnImage'} className={s.ribbonContainer}>
            {this.renderRibbon(RibbonPlacementId.OnImage)}
          </ConditionalRender>
        )}
        {!shouldShowMobile && (
          <ConditionalRender by={'showQuickView'} className={s.quickViewButtonContainer}>
            {this.renderQuickViewButton()}
          </ConditionalRender>
        )}
      </ProductMedia>
    );
  }

  private renderPrice() {
    const {
      globals: {
        allowFreeProducts,
        textsMap: {
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        },
        experiments: {shouldUseCommonDiscountPricingMethods},
      },
      product,
    } = this.props;

    /* istanbul ignore next: todo tests @titk  */
    const productWithPriceInfo = this.canAddProductToCart
      ? {
          ...product,
          ...this.productVariantInfo /* istanbul ignore next: todo tests @titk */?.priceInfo,
        }
      : product;

    return (
      <ProductPrice
        product={productWithPriceInfo}
        allowFreeProducts={allowFreeProducts}
        textsMap={{
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        }}
        shouldUseCommonDiscountPricingMethods={shouldUseCommonDiscountPricingMethods}
        fromPrice={this.productPriceRange}
        priceBreakdown={this.props.globals.priceBreakdown}
        isRTL={this.props.globals.isRTL}
        sendClickShippingInfoLinkSf={this.props.globals.sendClickShippingInfoLinkSf}
      />
    );
  }

  private static renderLineBetweenNameAndPrice() {
    return (
      <div className={s.productDividerWrapper}>
        <hr data-hook={DataHook.LineBetweenNameAndPrice} className={s.productDivider} aria-hidden="true" />
      </div>
    );
  }

  private renderQuickViewButton() {
    return (
      <button
        className={s.quickViewButton}
        data-hook={DataHook.QuickViewButton}
        tabIndex={-1}
        aria-hidden="true"
        onClick={this.handleQuickViewButtonClick}>
        {this.props.globals.textsMap.quickViewButtonText}
      </button>
    );
  }

  private renderRibbon(ribbonPlacement: RibbonPlacementId) {
    const {
      product: {ribbon},
      globals: {shouldShowMobile},
    } = this.props;

    return <ProductRibbon shouldShowMobile={shouldShowMobile} ribbon={ribbon} placement={ribbonPlacement} />;
  }

  private handleQuickViewButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const {
      globals: {openQuickView},
      product: {id: productId},
      index,
    } = this.props;
    const {quantity} = this.state;
    const selectionIds = this.productVariantInfo?.variantSelectionIds || [];

    event.preventDefault();
    event.stopPropagation();

    openQuickView({productId, index, selectionIds, quantity});
  }

  private handleQuantityCounterChange(val: string) {
    const {t} = this.props;
    const {min, max} = this.state.limits.quantity;
    const quantity = parseInt(val, 10) || 0;

    const minimumError = quantity < min && t('quantityMinimumAmountSR', {minimum: min});
    const maximumError = quantity > max && t('quantityMaximumAmountSR', {inventory: max});

    this.setState(
      ({errors}) => {
        const nextErrors = {...errors, quantity: minimumError || maximumError};
        return {quantity, errors: nextErrors};
      },
      () => {
        if (this.state.errors.quantity) {
          this.props.a11yAnnouncer.announce(this.state.errors.quantity);
        } else {
          this.props.a11yAnnouncer.announce(
            this.props.t('quantityTotalSR', {
              quantity,
            })
          );
        }
      }
    );
  }

  private readonly handleSelectionsChange = (selections: number[], data: ProductOptionsChangeData) =>
    this.setState(
      {
        selections,
      },
      () => {
        const {
          props: {
            globals: {handleProductsOptionsChange},
            product: {id: productId},
          },
        } = this;

        const {optionType} = data;

        handleProductsOptionsChange({
          productId,
          selectionIds: selections,
          optionType,
        });
      }
    );

  private handleProductItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {handleProductItemClick},
      product: {id: productId},
      index,
      disabled,
    } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }
    handleProductItemClick({
      biData: {
        productId,
        index,
      },
    });
  }

  public componentDidUpdate(prevProps: Readonly<IProductItemProps>): void {
    const {
      product,
      globals: {addedToCartStatus: prevAddedToCartStatus},
    } = prevProps;
    const {
      globals: {addedToCartStatus, shouldShowAddToCartSuccessAnimation, stylesParams},
      a11yAnnouncer,
      t,
    } = this.props;

    const previousHoverType = this.hoverType;
    const currentHoverType = this.props.globals.styles.get(stylesParams.gallery_hoverType).value;
    this.hoverType = currentHoverType;

    if (previousHoverType !== currentHoverType) {
      this.setState({showHoverPreview: true});

      this.debouncedStopHoverPreview();
    }

    const prevAddToCartStatus = prevAddedToCartStatus[product.id];
    const addToCartStatus = addedToCartStatus[product.id];

    if (
      shouldShowAddToCartSuccessAnimation &&
      prevAddToCartStatus !== AddToCartActionStatus.SUCCESSFUL &&
      addToCartStatus === AddToCartActionStatus.SUCCESSFUL
    ) {
      a11yAnnouncer.announce(t('addToCartSuccessSR', {productName: product.name}));
    }
  }

  private get isAddToCartEnabled() {
    const currentState = this.props.globals.productsManifest[this.props.product.id].addToCartState;
    const isEnabledState = currentState === AddToCartState.ENABLED;
    return isEnabledState || this.isPreOrderState;
  }

  private get canAddProductToCart() {
    const {product} = this.props;
    return product.isInStock || this.isPreOrderState;
  }

  private get productVariantInfo() {
    const {
      globals: {productsVariantInfoMap},
      product,
    } = this.props;

    return productsVariantInfoMap?.[product.id];
  }

  private get productPriceRange(): string | undefined {
    const {
      globals: {productsPriceRangeMap},
      product,
    } = this.props;

    return productsPriceRangeMap?.[product.id];
  }

  private readonly getRevealClassNames = () => {
    if (this.props.globals.isOptionsRevealEnabled) {
      const hasOptions = this.props.product.options.length > 0;
      const hasSingleOption =
        this.props.product.options.length === 1 ||
        this.props.product.options.every(({optionType}) => optionType === ProductOptionType.COLOR);
      const hasSelections = this.productVariantInfo.variantSelectionIds.length > 0;
      const shouldBeHidden = hasOptions && hasSingleOption && !hasSelections;
      return {
        [s.hiddenModeHidden]: shouldBeHidden,
        [s.hiddenModeVisible]: !shouldBeHidden,
      };
    }
    return {};
  };

  private readonly renderQuantityCounter = () => {
    const {
      globals: {shouldShowMobile, textsMap},
    } = this.props;

    const {
      quantity,
      limits: {
        quantity: {min, max},
      },
    } = this.state;

    const errors = {
      error: !!this.state.errors.quantity,
      errorMessage: this.state.errors.quantity,
    };

    return (
      <div className={classNames(s.quantity, this.getRevealClassNames())} data-hook={DataHook.QuantityCounterWrapper}>
        <Counter
          {...errors}
          decrementAriaLabel={textsMap.quantityRemoveSR}
          incrementAriaLabel={textsMap.quantityAddSR}
          aria-label={textsMap.quantityChooseAmountSR}
          inputAriaLabel={textsMap.quantityInputSR}
          onChange={this.handleQuantityCounterChange}
          data-hook={DataHook.QuantityCounter}
          value={quantity}
          min={min}
          max={max}
          className={classNames(
            styleQuantity(quantityStyle.quantityCounter, {
              customized: true,
            }),
            {
              [s.showOnHover]: !shouldShowMobile,
            }
          )}
        />
      </div>
    );
  };

  public renderOptions = () => {
    const {product} = this.props;

    return (
      <div data-hook={DataHook.Options}>
        <ProductOptionsWithGlobals
          variantsAvailability={this.productVariantInfo.selectionsAvailability}
          onSelectionIdsChange={this.handleSelectionsChange}
          product={product}
          selectionIds={this.productVariantInfo.variantSelectionIds}
          isItemHovered={this.state.isHovered}
        />
      </div>
    );
  };

  private get isPreOrderState(): boolean {
    const {addToCartState} = this.props.globals.productsManifest[this.props.product.id];
    return addToCartState === AddToCartState.PRE_ORDER;
  }

  private get currentMedia() {
    const {product} = this.props;

    return this.productVariantInfo?.mediaItems ?? product.media;
  }

  public onAddToCartClicked = () => {
    this.setState({quantity: 1});
  };

  public renderAddToCartButton = () => {
    const {product, index} = this.props;
    const {quantity} = this.state;

    const {
      shouldShowMobile,
      addedToCartStatus,
      handleAddToCart,
      updateAddToCartStatus,
      productsManifest,
      textsMap: {
        galleryAddToCartButtonText,
        addToCartContactSeller,
        addToCartOutOfStock,
        galleryAddToCartPreOrderButtonText,
      },
      styles,
      stylesParams,
      shouldShowAddToCartSuccessAnimation,
    } = this.props.globals;

    const shouldShowOnHover = styles.get(stylesParams.gallery_addToCartButtonShowOnHover);

    return (
      <AddToCart
        product={product}
        index={index}
        quantity={quantity}
        onAddToCartClicked={this.onAddToCartClicked}
        showOnHoverClassName={s.showOnHover}
        addedToCartStatus={addedToCartStatus}
        addToCartState={productsManifest[this.props.product.id].addToCartState}
        handleAddToCart={handleAddToCart}
        updateAddToCartStatus={updateAddToCartStatus}
        galleryAddToCartButtonText={galleryAddToCartButtonText}
        addToCartContactSeller={addToCartContactSeller}
        addToCartOutOfStock={addToCartOutOfStock}
        galleryAddToCartPreOrderButtonText={galleryAddToCartPreOrderButtonText}
        shouldShowAddToCartSuccessAnimation={shouldShowAddToCartSuccessAnimation}
        shouldShowMobile={shouldShowMobile}
        shouldShowOnHover={shouldShowOnHover}
      />
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    const {index, globals, product, style, t} = this.props;
    const {
      shouldShowMobile,
      shouldShowProductOptions,
      isLiveSiteMode,
      isPreviewMode,
      productsManifest,
      styles,
      stylesParams,
      experiments,
    } = globals;
    const {
      isAllowGalleryProductRoundCornersInViewer,
      addRatingSummarySlotToGallery,
      galleryProductOptionFixMobileDropdownBehavior,
    } = experiments;

    if (!productsManifest[product.id]) {
      return null;
    }

    const {showHoverPreview} = this.state;
    const options = this.props.product.options;
    const noOptions = options.length === 0;
    const singleOption = options.length === 1;
    const multipleColorOptions = !noOptions && options.every(({optionType}) => optionType === ProductOptionType.COLOR);
    const productPageUrl = productsManifest[product.id].url;
    const ariaLabelBase = product.name;
    const ariaLabel = product.ribbon ? `${ariaLabelBase}. ${product.ribbon}` : ariaLabelBase;
    const hoverType = styles.get(stylesParams.gallery_hoverType).value;
    const shouldChangeHoverState = galleryProductOptionFixMobileDropdownBehavior ? !shouldShowMobile : true;

    return (
      <div
        data-slug={product.urlPart}
        role="group"
        aria-label={t('carouselContainerLabel', {productName: ariaLabel})}
        onMouseEnter={() => shouldChangeHoverState && this.setState({isHovered: true})}
        onMouseLeave={() => shouldChangeHoverState && this.setState({isHovered: false})}
        style={style}
        data-hook={DataHook.Root}
        className={classNames(
          s.productItem,
          shouldShowMobile || (hoverType === HoverType.Alternate && this.currentMedia.length < 2)
            ? s.none
            : s[hoverType],
          {
            [s.hoverPreview]: showHoverPreview,
            [s.roundCorners]: isAllowGalleryProductRoundCornersInViewer,
            [s.oddProductItem]: index % 2,
          }
        )}>
        <a
          href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
          tabIndex={-1}
          onClick={this.handleProductItemClick}
          className={classNames(s.productItemLink, s.customizePadding)}
          data-hook={DataHook.LinkContainer}>
          {this.renderImage()}
        </a>
        <div data-hook={DataHook.NotImageContainer} className={s.notImageContainer}>
          <div className={s.productDetailsContainer}>
            <a
              href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
              onClick={this.handleProductItemClick}
              className={s.productDetailsLink}
              data-hook={DataHook.ProductDetailsLink}
              ref={this.productLink}>
              <ConditionalRender by={'notShowProductName'}>{this.renderSrOnlyName()}</ConditionalRender>
              <ConditionalRender by={'showProductDetails'}>
                <div className={s.productDetails} data-hook={DataHook.ProductDetails}>
                  {product.ribbon && (
                    <ConditionalRender by={'shouldShowRibbonOnProductInfo'} className={s.ribbonContainer}>
                      {this.renderRibbon(RibbonPlacementId.ProductInfo)}
                    </ConditionalRender>
                  )}
                  <ConditionalRender by={'gallery_showProductName'}> {this.renderName()}</ConditionalRender>
                  <ConditionalRender by={'gallery_showDividers'}>
                    {ProductItem.renderLineBetweenNameAndPrice()}
                  </ConditionalRender>
                  <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
                    {this.renderPrice()}
                  </ConditionalRender>
                  <ConditionalRender by={'gallery_showDiscountName'}>{this.renderDiscountRuleName()}</ConditionalRender>
                </div>
              </ConditionalRender>
            </a>
            {addRatingSummarySlotToGallery && (
              <div className={s.slotContainer}>
                <SlotContextProvider resourceId={product.id}>
                  <SlotsPlaceholder
                    slotId={SlotIds.ProductGalleryDetailsSlot1}
                    data-hook={DataHook.SlotsPlaceholderContainer}
                  />
                </SlotContextProvider>
              </div>
            )}
            {this.canAddProductToCart && shouldShowProductOptions && (
              <ConditionalRender
                by={shouldShowMobile ? 'mobile:gallery_showProductOptions' : 'gallery_showProductOptionsButton'}
                className={s.productInputs}>
                {this.renderOptions()}
              </ConditionalRender>
            )}
            {this.isAddToCartEnabled && (
              <div>
                <ConditionalRender
                  by={shouldShowMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'}>
                  <ConditionalRender
                    by={shouldShowMobile ? 'mobile:gallery_showQuantity' : 'gallery_showAddToCartQuantity'}>
                    {noOptions && this.renderQuantityCounter()}
                    {(singleOption || multipleColorOptions) && (
                      <ConditionalRender
                        by={
                          shouldShowMobile ? 'mobile:gallery_showProductOptions' : 'gallery_showProductOptionsButton'
                        }>
                        {this.renderQuantityCounter()}
                      </ConditionalRender>
                    )}
                  </ConditionalRender>
                </ConditionalRender>
              </div>
            )}
          </div>
          <ConditionalRender
            by={shouldShowMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'}
            className={s.productFooter}>
            {this.renderAddToCartButton()}
          </ConditionalRender>
        </div>
      </div>
    );
  }
}

export const ProductItemWithGlobals = withGlobals(withTranslations()(ProductItem));
